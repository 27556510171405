import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { fetchMetaInsightByDate, fetchTicketureByAttendingDate } from '../services/ticketure';
import AccumulateChart from './UI-components/Accumulate/AccumulateChart';

const AccumulateView = () => {
  const dispatch = useDispatch();
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(dayjs().subtract(16, 'week'));
  const [endDate, setEndDate] = useState(dayjs());

  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: (params) => {
      const value = params || 0;
      const wholeNumValue = Number(value).toFixed(0);
      return `$${Number(wholeNumValue).toLocaleString()}`;
    },
  };

  const largeNumber = {
    type: 'number',
    width: 130,
    valueFormatter: (params) => {
      const value = params || 0;
      const wholeNumValue = Number(value).toFixed(0);
      return Number(wholeNumValue).toLocaleString();
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');

        const [ticketDataResponse, metaInsightsResponse] = await Promise.all([
          dispatch(fetchTicketureByAttendingDate({ startDate: formattedStartDate, endDate: formattedEndDate })),
          dispatch(fetchMetaInsightByDate({ startDate: formattedStartDate, endDate: formattedEndDate })),
        ]);

        const ticketData = ticketDataResponse.payload || [];
        const metaInsightsData = metaInsightsResponse.payload || [];

        const mergedData = {};

        ticketData.forEach((item) => {
          const saleDate = item.sale_date;

          if (!saleDate) return;

          if (!mergedData[saleDate]) {
            mergedData[saleDate] = {
              id: `accumulate-${saleDate}`,
              sale_date: saleDate,
              daily_ticket_count: 0,
              spend: 0,
              impressions: 0,
              clicks: 0,
              unique_clicks: 0,
            };
          }

          mergedData[saleDate].daily_ticket_count += Number(item.daily_ticket_count) || 0;
        });

        metaInsightsData.forEach((item) => {
          const saleDate = item.date_start;

          if (!saleDate) return;

          if (!mergedData[saleDate]) {
            mergedData[saleDate] = {
              id: `accumulate-${saleDate}`,
              sale_date: saleDate,
              daily_ticket_count: 0,
              spend: 0,
              impressions: 0,
              clicks: 0,
              unique_clicks: 0,
            };
          }

          mergedData[saleDate].spend += Number(item.spend) || 0;
          mergedData[saleDate].impressions += Number(item.impressions) || 0;
          mergedData[saleDate].clicks += Number(item.clicks) || 0;
          mergedData[saleDate].unique_clicks += Number(item.unique_clicks) || 0;
        });

        setAccumulatedData(Object.values(mergedData));
      } catch (error) {
        console.error('Error fetching accumulated data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, startDate, endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper style={{ padding: '10px' }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <Box display="flex" justifyContent="center" alignItems="center" gap={2} marginBottom={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <h2>Accumulated View by Sale Date</h2>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  flex: '1 1 45%',
                  margin: '10px',
                  backgroundColor: '#fdfdfd',
                  border: '1px solid #f1f2f6',
                  borderRadius: '5px',
                }}
              >
                <AccumulateChart
                  data={accumulatedData}
                  xKey="sale_date"
                  yKeys={['daily_ticket_count', 'spend']}
                  yLabels={['Daily Ticket Count', 'Spend']}
                />
              </div>
            </div>
            <div style={{ height: '70vh', width: '100%', overflowX: 'auto', position: 'relative', margin: 'auto' }}>
              {accumulatedData && accumulatedData.length > 0 ? (
                <DataGrid
                  rows={accumulatedData}
                  getRowId={(row) => row.id}
                  columns={[
                    { field: 'sale_date', headerName: 'Sale Date', width: 150 },
                    { field: 'daily_ticket_count', headerName: 'Daily Ticket Count', width: 200, ...largeNumber },
                    { field: 'spend', headerName: 'Spend', width: 150, ...usdPrice },
                    { field: 'impressions', headerName: 'Impressions', width: 150, ...largeNumber },
                    { field: 'clicks', headerName: 'Clicks', width: 150, ...largeNumber },
                    { field: 'unique_clicks', headerName: 'Unique Clicks', width: 150, ...largeNumber },
                  ]}
                  pageSize={5}
                  slots={{ toolbar: GridToolbar }}
                  style={{ width: '100%', minWidth: '600px', position: 'absolute' }}
                  showCellVerticalBorder
                  showColumnVerticalBorder
                />
              ) : (
                <p>No data available to display in the table.</p>
              )}
            </div>
          </>
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default AccumulateView;