import React from 'react';
import Chart from 'react-apexcharts';

const AccumulateChart = ({ data, xKey, yKeys, yLabels }) => {
  // Filter out invalid data points
  const validData = data.filter((item) => item && item[xKey] !== null && item[xKey] !== undefined);

  // Ensure the data is sorted by sale_date
  const sortedData = [...validData].sort((a, b) => new Date(a[xKey]) - new Date(b[xKey]));

  // Prepare series data for ApexCharts
  const series = yKeys.map((key, index) => ({
    name: yLabels[index],
    data: sortedData.map((item) => ({
      x: new Date(item[xKey]).toLocaleDateString(), // Format sale_date
      y: item[key] || 0, // Default to 0 if metric is missing
    })),
  }));

  // ApexCharts options
  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: { show: true },
      zoom: { enabled: true, type: 'xy', autoScaleYaxis: true },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => new Date(value).toLocaleDateString(),
      },
      title: { text: 'Sale Date' },
    },
    yaxis: {
      title: { text: 'Metrics' },
      labels: {
        formatter: (value) => (Math.abs(value) >= 1 ? value.toFixed(0) : value.toFixed(2)),
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: { formatter: (value) => new Date(value).toLocaleDateString() },
    },
    stroke: { curve: 'smooth', width: 3 },
    legend: { position: 'top' },
  };

  return <Chart options={options} series={series} type="line" height={400} />;
};

export default AccumulateChart;