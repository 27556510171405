import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthProvider, useAuth } from './context/AuthContext';
import { QueryClientProvider, QueryClient } from 'react-query';
import MainMap from './components/MainMap';
import Login from './components/Login';
import Forecast from './components/Forecast';
import Competition from './components/Competition';
import Layout from './Layout';
import Marketing from './components/Marketing';
import Signup from './components/Signup';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import MarketPage from './components/MarketPage';
import ZipMap from './components/ZipMap';
import Employees from './components/Employees';
import Drilldown from './components/Drilldown';
import { useSelector } from 'react-redux';
import { GetUserProfile } from './middlewares/auth';
import jwtDecoder from './config/jwt-decoder';
import AccumulateView from './components/AccumulateView';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, authLoading } = useAuth();
  if (authLoading) {
    return <div></div>;
  }
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {

  const state = useSelector((state) => state)
  const queryClient = new QueryClient();
  const token = localStorage.getItem("access_token")

  const theme = createTheme({
    typography: {
      fontFamily: 'Satoshi, Arial, sans-serif',
    },
  });

  async function getUserProfile() {
    if (!token) return;
    try {
      const decoded = jwtDecoder(token);
      const id = decoded.sub;
      const response = await GetUserProfile(id);
      if (response.status === 200) {
        console.log(response);
        localStorage.setItem("user", JSON.stringify(response.data))
      }
      else {
        console.log("Failed to fetch user details. Status code:", response.status);
      }
    }
    catch (error) {
      console.log("Error fetching user details:", error);
    }
  }

  useEffect(() => {
    getUserProfile()
  }, [token])

  console.log("store ===>", state);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<ProtectedRoute element={<Layout />} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/market/:marketId" element={<MarketPage />} />
                <Route path="/map" element={<MainMap />} />
                <Route path="/ZipMap" element={<ZipMap />} />
                <Route path="/forecast" element={<Forecast />} />
                <Route path="/competition" element={<Competition />} />
                <Route path="/marketing" element={<Marketing />} />
                <Route path="/drilldown" element={<Drilldown />} />
                <Route path="/accumulate" element={<AccumulateView />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;