import { createSlice } from '@reduxjs/toolkit';
import {
    fetchTicketureAllEventIDs,
    fetchTicketureAllMarkets,
    fetchTicketureByMarket,
    fetchTicketureByEventIDs,
    fetchTicketureByOpenDate,
    fetchTicketureByAttendingDate,
    fetchTicketureZipCodeByEventID,
    fetchPageViewsByDate,
    fetchMetaInsightByDate,
    fetchMetaInsightByDate2,
    fetchMetaInsightByMarket
} from '../services/ticketure'; 

const initialState = {
    allEventIds: [],
    eventData: [],
    defaultEventIDs: [],
    marketData: [],
    salesData: [],
    zipCodeSalesData: [],
    ticketureMarkets: [],
    metaInsight1: [],
    metaInsight2: [],
    metaInsightMarkets: [],
    pageViews: [],
    forecastData: [],
    loading: false,
    error: null,
};

const ticketureSlice = createSlice({
    name: 'ticketure',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // fetchTicketureAllEventIDs
        builder
            .addCase(fetchTicketureAllEventIDs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureAllEventIDs.fulfilled, (state, action) => {
                state.loading = false;
                state.allEventIds = action.payload;
            })
            .addCase(fetchTicketureAllEventIDs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // fetchTicketureAllMarkets
        builder
            .addCase(fetchTicketureByEventIDs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureByEventIDs.fulfilled, (state, action) => {
                state.loading = false;
                state.eventData = action.payload;
            })
            .addCase(fetchTicketureByEventIDs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // fetchTicketureByOpenDate
        builder
            .addCase(fetchTicketureByOpenDate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureByOpenDate.fulfilled, (state, action) => {
                state.loading = false;
                state.defaultEventIDs = action.payload;
            })
            .addCase(fetchTicketureByOpenDate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // fetchTicketureByAttendingDate
        builder
            .addCase(fetchTicketureByAttendingDate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureByAttendingDate.fulfilled, (state, action) => {
                state.loading = false;
                state.defaultEventIDs = action.payload;
            })
            .addCase(fetchTicketureByAttendingDate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
        

        // fetchTicketureByMarket
        builder
            .addCase(fetchTicketureByMarket.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureByMarket.fulfilled, (state, action) => {
                state.loading = false;
                state.marketData = action.payload;
            })
            .addCase(fetchTicketureByMarket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // fetchTicketureZipCodeByEventID
        builder
            .addCase(fetchTicketureZipCodeByEventID.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureZipCodeByEventID.fulfilled, (state, action) => {
                state.loading = false;
                state.zipCodeSalesData = action.payload; 
            })
            .addCase(fetchTicketureZipCodeByEventID.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        builder
            .addCase(fetchTicketureAllMarkets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTicketureAllMarkets.fulfilled, (state, action) => {
                state.loading = false;
                state.ticketureMarkets = action.payload;
            })
            .addCase(fetchTicketureAllMarkets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        builder
            .addCase(fetchMetaInsightByDate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMetaInsightByDate.fulfilled, (state, action) => {
                state.loading = false;
                state.metaInsight1 = action.payload;
            })
            .addCase(fetchMetaInsightByDate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        builder
            .addCase(fetchMetaInsightByDate2.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMetaInsightByDate2.fulfilled, (state, action) => {
                state.loading = false;
                state.metaInsight2 = action.payload;
            })
            .addCase(fetchMetaInsightByDate2.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        builder
            .addCase(fetchMetaInsightByMarket.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMetaInsightByMarket.fulfilled, (state, action) => {
                state.loading = false;
                state.metaInsightMarkets = action.payload;
            })
            .addCase(fetchMetaInsightByMarket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        builder
            .addCase(fetchPageViewsByDate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPageViewsByDate.fulfilled, (state, action) => {
                state.loading = false;
                state.pageViews = action.payload;
            })
            .addCase(fetchPageViewsByDate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

    },
});

export const ticketureReducer = ticketureSlice.reducer;